import React from 'react';
import { NextPage } from 'next';
import { HomePageContentLayout } from '@/finsight/ui/common/components/ContentLayouts';
import Homepage from '@/finsight/ui/components/static/Homepage';
import withFinsightGlobalProps from '@/finsight/application/withFinsightGlobalProps';

export const getServerSideProps = withFinsightGlobalProps(() => ({
  props: {},
}));

type TProps = {};

const Home: NextPage<TProps, any> = () => (
  <HomePageContentLayout>
    <Homepage />
  </HomePageContentLayout>
);

export default Home;
